/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

body {
    max-width: 1920px;
    margin: 0 auto;
}
img {
    display: block;
    max-width: 100%;
}
a {
    outline: 0!important;
}
.center {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    background: #f1f1f1;;
    position: relative;
    .top_top {
        widows: 100%;
        height: 45px;
        background: #fff;
        p {
            float: left;
            line-height: 45px;
            font-size: 14px;
            color: #666;
        }
    }
    .head_top {
        width: 100%;
        padding: 5px 0;
        .fl {
            a {
                float: left;
                img {
                    height: 120px;
                    display: block;
                }
            }
        }
        .fr {
            padding-top: 30px;
            .tel {
                float: right;
                p {
                    padding-left: 55px;
                    background: url(../img/tel.png) no-repeat left center;
                    i {
                        font-size: 16px;
                        color: #666;
                        display: block;
                        font-style: normal;
                        font-weight: bold;
                    }
                    span {
                        display: block;
                        color: #e62129;
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.nav {
    width: 100%;
    background: #015aaa;
    height: 60px;
    ul {
        li {
            float: left;
            width: 11.11%;
            height: 60px;
            position: relative;
            a {
                width: 100%;
                text-align: center;
                line-height: 60px;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: #46af33;
                }
                &.active {
                    background: #46af33;
                }
            }
            .v_list {
                position: absolute;
                width: 100%;
                top: 60px;
                background: #015aaa;
                left: 0;
                z-index: 99;
                display: none;
                a {
                    color: #fff;
                    line-height: 36px;
                    font-size: 14px;
                    transition: .5s;
                    &:hover  {
                        transition: .5s;
                        background: #46af33;
                    }
                }
            }
            &:hover .v_list {
                display: block;
            }
        }
    }
}

.fixed {
    position: fixed;
    max-width: 1920px;
    top: 0;
    z-index: 10000;
    float: none !important;
}

.pc_banner {
    width: 100%;
    overflow: hidden;
    .pcbanner {
        position: relative;
        z-index: 10;
        .swiper-slide {
            width: 100%;
            position: relative;
            .b_bg {
                img {
                    width: 100%;
                    display: block;
                }
            }
        }
        .pagination {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            text-align: center;
            z-index: 10;
            span {
                width: 12px;
                height: 12px;
                background: #fff;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #f6ab00;
                }
            }
        }
        .next {
            position: absolute;
            left: 2%;
            top: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            z-index: 10;
            background: url(../img/ble.png) no-repeat center;
            cursor: pointer;
            display: none;
        }
        .prev {
            position: absolute;
            right: 2%;
            top: 50%;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            z-index: 10;
            background: url(../img/bri.png) no-repeat center;
            cursor: pointer;
            display: none;
        }
    }
    &:hover {
        .pcbanner {
            .next {
                display: block;
            }
            .prev {
                display: block;
            }
        }
    }
}

.m_banner {
    display: none;
    width: 100%;
    overflow: hidden;
    margin-top: 60px;
    .mbanner {
        position: relative;
        .swiper-slide {
            img {
                width: 100%;
                display: block;
            }
        }
        .pagination {
            text-align: center;
            position: absolute;
            bottom: 5px;
            width: 100%;
            z-index: 90;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #eee;
                margin: 0 4px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: #f6ab00;
                    color: #fff;
                }
            }
        }
    }
}

.m_header {
    width: 100%;
    display: none;
    .m_head_content {
        height: 60px;
        width: 100%;
        padding: 0 2%;
        background: #f1f1f1;
        position: fixed;
        top: 0;
        left: 0;
        height: 60px;
        z-index: 999;
        box-shadow: 0px 5px 10px 1px #999;
    }
    .m_head_logo {
        width: 100%;
        height: 60px;
        a {
            float: left;
            padding-top: 5px;
            img {
                height: 50px;
            }
        }
        span {
            float: right;
            margin-top: 10px;
            img {
                height: 40px;
            }
        }
    }  
}

.app_menu {
    width: 100%;
    height: 500%;
    z-index: 99;
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    ul {
        background: #015aaa;
        li {
            a {
                
                font-size: 14px;
                width: 100%;
                height: 42px;
                line-height: 42px;
                color: #fff;
                display: block;
                padding-left: .15rem;
                border-top: 1px solid #eee;
            }
        }
    }
}

.container {
    width: 100%;
    overflow: hidden;
}

.main {
    width: 100%;
    overflow: hidden;
}

.hot_ci {
    border-bottom: 1px solid #ddd;
    .text {
        padding: 20px 0;
        .fl {
            p {
                line-height: 40px;
                span {
                    font-size: 18px;
                    color: #036eb8;
                    display: block;
                    font-weight: bold;
                    float: left;
                    padding-right: 5px;
                }
                a {
                    float: left;
                    font-size: 14px;
                    color: #000;
                    margin-right: 20px;
                }
            }
        }
        .fr {
            position: relative;
            width: 280px;
            height: 40px;
            input {
                outline: 0;
                border: none;
            }
            .inp1 {
                width: 280px;
                height: 40px;
                border: 1px solid #036eb8;
                background: none;
                padding-left: 15px;
                font-size: 16px;
            }
            .inp2 {
                position: absolute;
                right: 0;
                top: 0;
                width: 55px;
                height: 40px;
                background: url(../img/sea.png) no-repeat center #036eb8;
                background-size: 22px;
            }
        }
    }
}

.i_box4 {
    width: 100%;
    padding: 10px 0;
    .list {
        width: 100%;
        margin-top: 30px;
        .text1 {
            width: 65%;
            float: left;
        }
        .text2 {
            width: 35%;
            float: right;
            padding-left: 3%;
            .t_tit {
                height: 60px;
                background: url(../img/xian2.jpg) no-repeat 0 bottom;
                margin-bottom: 35px;
            }
        }
        .t_tit {
            height: 60px;
            background: url(../img/xian1.jpg) no-repeat 0 bottom;
            margin-bottom: 35px;
            span {
                line-height: 50px;
                font-weight: bold;
                font-size: 18px;
                float: left;
            }
            a {
                float: right;
                width: 55px;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                color: #666;
                background: url(../img/more.gif) no-repeat right center;
            }
        }
        .t_box {
            .fl {
                width: 50%;
                padding-right: 3%;
            }
            .fr {
                width: 50%;
                padding-left: 3%;
            }
        }
        .gs_text {
            span {
                display: block;
                height: 60px;
                line-height: 60px;
                width: 100%;
                overflow: hidden;
                border-bottom: 1px solid #e1e1e1;
                font-weight: bold;
                font-size: 16px;
            }
            p {
                line-height: 30px;
                font-size: 14px;
                color: #555;
                height: 120px;
                overflow: hidden;
                margin: 12px 0;
            }
            i {
                display: block;
                width: 108px;
                height: 34px;
                background: #eb9000;
                text-align: center;
                font-size: 14px;
                color: #fff;
                line-height: 34px;
                font-style: normal;
            }
        }
        .hy_text {
            ul {
                li {
                    position: relative;
                    margin-top: 6px;
                    i {
                        width: 22px;
                        height: 22px;
                        background: #8f8c8b;
                        color: #fff;
                        text-align: center;
                        line-height: 22px;
                        margin-top: 5px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-style: normal;
                    }
                    span {
                        display: block;
                        width: 95%;
                        height: 34px;
                        line-height: 34px;
                        overflow: hidden;
                        font-size: 14px;
                        padding-left: 32px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    &:hover {
                        i {
                            background: #eb9000;
                        }
                        span {
                            color: #eb9000;
                        }
                    }
                }
            }
        }
    }
    .que_list {
        width: 100%;
        ul {
            li {
                color: #545455;
                line-height: 21px;
                margin-bottom: 32px;
                a {
                    display: block;
                    background: url(../img/wen.gif) no-repeat left center;
                    padding-left: 28px;
                    color: #333;
                    font-weight: bold;
                    font-size: 14px;
                }
                p {
                    background: url(../img/da.gif) no-repeat left 2px;
                    padding-left: 28px;
                    margin-top: 10px;
                    line-height: 22px;
                    color: #555;
                    font-size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.main_l {
    width: 24%;
    padding-bottom: 20px;
    .text {
        width: 100%;
        h4 {
            background: #015aaa;
            display: block;
            font-size: 24px;
            color: #fff;
            line-height: 57px;
            text-align: center;
        }
        ul {
            border: 1px solid #a7a7a7;
            padding: 8px 0;
            li {
                display: block;
                margin: 0 2%;
                width: 96%;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                text-indent: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: url(../img/ri3.png) no-repeat 10px center #f1f1f1;
                margin: 8px auto;
                font-weight: bold;
                transition: all .0s ease;
                color: #333;
                border-radius: 5px;
                border: 1px solid #ddd;
            }
        }
    }
    .main_contact {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
        .text_t {
            background: #015aaa;
            margin-top: -1px;
            padding: 15px 4%;
            p {
                font-size: 14px;
                color: #666;
                display: block;
                line-height: 25px;
                color: #fff;
            }
        }
    }
}

.main_r {
    width: 74%;
}

.pro_tit {
    height: 43px;
    border-top: 2px solid #46af33;
    border-bottom: 1px solid #46af33;
    margin-bottom: 10px;
    span {
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
        padding: 0 10px;
        float: left;
    }
    a {
        font-family: Arial;
        padding: 0 10px;
        color: #46af33;
        line-height: 40px;
        float: right;
    }
}

.i_box1 {
    width: 100%;
    padding: 40px 0;
}

.box1_list {
    ul {
        li {
            width: 31.33%;
            margin-right: 3%;
            margin-bottom: 2%;
            float: left;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .imgauto {
                width: 100%;
                height: 214px;
                border: 1px solid #ddd;
                overflow: hidden;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 15px;
                color: #333;
                display: block;
                text-align: center;
                margin: 6px 0;
                font-weight: bold;
            }
            &:hover {
                .imgauto img {
                    transform: scale(1.1);
                    transition: .5s;
                }
                span {
                    color: #015aaa;
                }

            }
        }
    }
}

.i_box3 {
    width: 100%;
    padding: 20px 0;
    .box {
        width: 100%;
        margin-top: 20px;
    }
}

.box3_list {
    width: 100%;
    ul {
        li {
            width: 100%;
            float: left;
            margin-top: 30px;
            .fl {
                width: 50%;
                float: right;
                img {
                    display: block;
                    width: 85%;
                    max-width: 100%;
                    float: right;
                }
            }
            .fr {
                width: 50%;
                float: left;
                .f_text {
                    margin-bottom: 40px;
                    .fr_tit {
                        width: 100%;
                        padding-left: 110px;
                        min-height: 101px;
                        background: url(../img/1.jpg) no-repeat left top;
                        margin-bottom: 15px;
                        span {
                            color: #1e5db0;
                            font-size: 36px;
                            font-weight: bold;
                            line-height: 40px;
                            display: block;
                            text-align: left;
                            padding-top: 15px;
                        }
                        i {
                            line-height: 40px;
                            font-size: 18px;
                            font-weight: 100;
                            text-transform: uppercase;
                            color: #999;
                            font-family: Arial;
                            font-style: normal;
                            display: block;
                        }
                    }
                    p {
                        display: block;
                        font-size: 16px;
                        margin-left: 40px;
                        text-align: left;
                        color: #666;
                        line-height: 30px;
                        padding-left: 40px;
                        background: url(../img/f.jpg) no-repeat left 13px;
                    }
                }
            }
            &.on {
                .fl {
                    float: left;
                    img {
                        float: left;
                    }
                }
                .fr {
                    float: right;
                }
            }
            &:nth-of-type(1){
                .fr .f_text:nth-of-type(2) {
                    .fr_tit {
                        background: url(../img/2.jpg) no-repeat left top;
                    }
                }
            }
            &:nth-of-type(2){
                .fr .f_text:nth-of-type(1) {
                    .fr_tit {
                        background: url(../img/3.jpg) no-repeat left top;
                    }
                }
                .fr .f_text:nth-of-type(2) {
                    .fr_tit {
                        background: url(../img/4.jpg) no-repeat left top;
                    }
                }
            }
        }
    }
}

.i_box5 {
    background: #f1f1f1;
    .box {
        padding: 50px 0;
        .fl {
            width: 50%;
            float: right;
            padding-left: 60px;
            h3 {
                line-height: 70px;
                font-size: 30px;
                font-weight: bold;
                color: #015aaa;
                border-bottom: 2px solid #015aaa;
                margin-bottom: 15px;
            }
            .text {
                p {
                    font-size: 14px;
                    color: #666;
                    display: block;
                    line-height: 24px;
                    margin-top: 10px;
                }
            }
            a {
                display: block;
                width: 136px;
                height: 39px;
                background: #eb9000;
                text-align: center;
                line-height: 39px;
                text-align: center;
                color: #fff;
                float: right;
                margin-top: 20px;
            }
        }
        .fr {
            width: 50%;
            float: left;
        }
    }
}

.footer {
    width: 100%;
    margin-top: 50px;
    padding: 30px 0;
    background: #464646;
    .foot_contact {
        width: 36%;
        float: left;
        p {
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            display: block;
            margin-bottom: 10px;
        }
    }
    .foot_nav {
        width: 450px;
        float: right;
        padding-top: 20px;
        ul {
            width: 220px;
            float: left;
            li {
                width: 50%;
                border-left: 1px solid #616161;
                padding-left: 20px;
                float: left;
                display: block;
                line-height: 28px;
                a {
                    font-size: 14px;
                    color: #fff;
                    display: block;
                    &:hover {
                        color: #eb9000;
                    }
                }
                &:first-child {
                    a {
                        font-weight: bold;
                        &:hover {
                            color: #97f3e7;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .foot_ewm {
        width: 200px;
        float: right;
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}

.copyright {
    background: #232323;
    .box {
        padding: 15px 0;
        .fl {
            font-size: 14px;
            color: #888;
            line-height: 24px;
        }
        .fr {
            padding-top: 5px;
            a {
                font-size: 14px;
                line-height: 100%;
                float: left;
                border-right: 1px solid #888;
                padding-right: 10px;
                margin-right: 10px;
                &:last-child {
                    border: none;
                    padding: 0;
                    margin: 0;
                }
                &:hover {
                    color: #eb9000;
                }
            }
        }
    }
}

.m_bottom {
    display: none;
    padding-bottom: 59px;
}
.m_fixedmenu {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #015aaa;
    padding: 5px 0;
    z-index: 110;
    a {
        width: 25%;
        float: left;
        img {
            height: 25px;
            display: block;
            margin: 0 auto;
        }
        span {
            display: block;
            text-align: center;
            font-size: 14px;
            color: #fff;
            margin-top: 5px;
        }
    }
}

.page_banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
        width: 100%;
        height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.page_wrap {
    padding: 30px 0;
    width: 100%;
    overflow: hidden;
}

.crumbs {
    width: 100%;
    height: 40px;
    background: #f3f3f3;
    border: 1px solid #e4e4e4;
    padding: 0 2%;
    overflow: hidden;
    span {
        float: left;
        line-height: 40px;
        font-size: 16px;
    }
    p {
        float: right;
        line-height: 40px;
        span {
            border-left: 6px solid #02b29c;
            padding-left: 5px;
            font-size: 14px;
            line-height: 110%;
            @include inline-block();
        }
        a {
            @include inline-block();
            font-size: 14px;
            color: #666;
            line-height: 110%;
            &:hover {
                color: #02b29c;
            }
        }
    }
}

.page_content {
    width: 100%;
    border: 1px solid #e4e4e4;
    margin-top: 0;
    border-top: none;
    padding-top: 10px!important;
}

.pro_list {
    margin-top: 12px;
    overflow: hidden;
}

.show_pages {
    width: 100%;
    background: #f5f5f5;
    padding: 0 1%;
    p {
        font-size: 14px;
        color: #666;
        line-height: 50px;
        a {
            font-size: 14px;
            color: #666;
            line-height: 50px;
            &:hover {
                color: #015aaa;
            }
        }
    }
}

.content {
    width: 100%;
    padding-bottom: 20px;
    padding: 0 1%;
    p {
        font-size: 14px;
        color: #666;
        display: block;
        line-height: 24px;
        margin-bottom: 10px;
    }
    img {
        display: block;
        max-width: 100%;
    }
}

.tag_list {
    margin: 20px 0 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    p {
        font-size: 14px;
        span {
            padding-right: 10px;
        }
        a {
            font-size: 14px;
            padding-right: 20px;
            &:hover {
                color: #015aaa;
            }
        }
    }
}

.yunu-link {
    width: 100%;
    margin: 0;
    padding: 20px 0;
    h4 {
        border-bottom: 1px solid #ddd;
        margin: 0;
        font-size: 18px;
        color: #015aaa;
        padding-bottom: 12px;
        small {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: normal;
            color: #999;
        }
    }
    ul {
        margin-left: -20px;
        li {
            float: left;
            margin-left: 20px;
            margin-top: 15px;
            a {
                font-size: 14px;
                &:hover {
                    color: #015aaa;
                }
            }
        }
    }
}

.show_tit {
    h3 {
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 10px;
        span {
            width: 130px;
            height: 40px;
            display: block;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            background: #015aaa;
        }
    }
}

.content_tit {
    padding: 20px 0;
    h3 {
        font-size: 24px;
        color: #000;
        display: block;
        text-align: center;
    }
    p {
        background: #f1f1f1;
        line-height: 40px;
        text-align: center;
        margin-top: 12px;
        span {
            font-size: 12px;
            color: #666;
            margin: 0 10px;
            @include inline-block();
        }
    }
}

.new_list {
    width: 100%;
    border: 1px solid #ddd;
    padding: 0 2%;
    border-top: none;
    ul {
        li {
            width: 100%;
            padding: 20px 0;
            float: left;
            padding-bottom: 20px;
            border-bottom: 1px dashed #ddd;
            .text {
                line-height: 25px;
                a {
                    font-size: 18px;
                    color: #333;
                    display: block;
                    float: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 60%;
                    font-weight: bold;
                }
                span {
                    font-size: 13px;
                    color: #999;
                    display: block;
                    float: right;
                }

            }
            p {
                font-size: 14px;
                color: #888;
                display: block;
                margin-top: 8px;
                line-height: 24px;
            }
            &:hover {
                .text {
                    a {
                        color: #015aaa;
                    }
                }
            }
        }
    }
}

.show_text {
    li {
        width: 49%;
        float: left;
        padding-right: 2%;
        padding-left: 15px;
        line-height: 30px;
        background: url(../img/dian2.png) no-repeat 5px center;
        a {
            font-size: 14px;
            color: #666;
            float: left;
            width: 60%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        span {
            float: right;
            font-size: 14px;
            color: #999;
        }
        &:hover {
            a {
                color: #015aaa;
            }
        }
    }
}

.page_menu {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    h3 {
        display: block;
        line-height: 50px;
        padding-left: 20px;
        background: #015aaa;
        font-size: 24px;
        color: #fff;
        font-weight: normal;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    ul {
        width: 100%;
        display: block;
        border: 1px solid #eee;
        li {
            display: block;
            margin: 6px;
            box-shadow: 0px 1px 10px 1px #ddd;
            a {
                font-size: 14px;
                display: block;
                color: #333;
                padding: 8px 20px;
                transition: .3s;
                padding-left: 40px;
            }
            &:hover {
                a {
                    background: #02b29c;
                    color: #fff;
                    transition: .3s;
                }
            }
        }
    }
}

.page_tit {
    width: 100%;
    border-bottom: 1px solid #ddd;
    span {
        font-size: 22px;
        color: #015aaa;
        font-weight: bold;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        border-bottom: 3px solid #015aaa;
        padding-bottom: 12px;
        float: left;
    }
    p {
        float: right;
        font-size: 13px;
        color: #666;
        padding-left: 20px;
        line-height: 44px;
        a {
            font-size: 13px;
            color: #666;
            &:hover {
                color: #015aaa;
            }
        }
    }
}




@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .about_wrap {
        padding: 20px 2%;
        .main_l {
            display: none;
        }
    }
}




@media screen and (max-width: 768px) {
    .hot_ci .text {
        padding: 10px 2%;
        .fl {
            display: none;
        }
        .fr {
            width: 100%;
            .inp1 {
                width: 100%;
            }
        }
    }

    .main_l {
        width: 100%;
        overflow: hidden;
    }
    
    .main_r {
        width: 100%;
        overflow: hidden;
    }

    .i_box1 {
        padding: 20px 2%;
        .main_l {
            display: none;
        }
        .main_r {
            width: 100%;
        }
    }

    .i_box3 {
        padding-top: 0;
        .i_pic {
            img {
                width: 100%;
                height: 90px;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
    }

    .box3_list {
        padding: 0 2%;
        ul li {
            margin-top: 0;
            .fl {
                width: 100%;
                display: none;
            }
            .fr {
                width: 100%;
            }
        }
    }

    .i_box5 {
        padding-top: 0;
        .i_pic {
            img {
                width: 100%;
                height: 120px;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        .box {
            padding: 0 2%;
            .fl {
                width: 100%;
                padding: 20px;
                h3 {
                    font-size: 24px;
                }
            }
            .fr {
                width: 100%;
            }
        }
    }

    .i_box4 {
        padding: 20px 2%;
        .box_tit em {
            display: none;
        }
        .list {
            .text1 {
                width: 100%;
            }
            .text2 {
                padding-left: 0;
                width: 100%;
                margin-top: 30px;
            }
        }
    }

    .footer {
        padding: 20px 2%;
        margin-top: 0;
        .foot_contact {
            width: 100%;
        }
        .foot_nav {
            width: 100%;
            padding-top: 10px;
            ul {
                display: none;
            }
            .foot_ewm {
                float: left;
            }
        }
    }

    .copyright {
        padding: 0 2%;
        .box {
            .fl {
                width: 100%;
                p {
                    display: block;
                    text-align: center;
                }
            }
            .fr {
                display: none;
            }
        }
    }

    .m_bottom {
        display: block;
    }
    .m_fixedmenu {
        display: block;
    }

    .pro_wrap {
        padding: 20px 2%;
    }

    .mhide {
        display: none;
    }

    .show_pages {
        p {
            width: 100%;
            line-height: 30px;
            a {
                line-height: 30px;
            }
            &.fr {
                margin-top: 10px;
            }
        }
    }

    .show_text {
        li {
            width: 100%;
        }
    }



}


@media screen and (max-width: 450px) {
    .box1_list ul li {
        width: 49%;
        margin-right: 2%;
        .imgauto {
            height: 1.2rem;
        }
        span {
            font-size: .11rem;
        }
        &:nth-child(3n){
            margin-right: 2%;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
        &:last-child {
            display: none;
        }
    }

    .box3_list {
        ul li {
            .fr {
                width: 100%;
                .f_text {
                    margin-bottom: .15rem;
                    .fr_tit {
                        height: 80px;
                        min-height: auto;
                        background: url(../img/1.jpg) no-repeat left top;
                        background-size: auto 100%;
                        padding-left: 88px;
                        span {
                            font-size: 24px;
                            padding-top: 15px;
                            line-height: 32px;
                        }
                        i {
                            font-size: .11rem;
                            line-height: 28px;
                        }
                    }
                    p {
                        padding-left: 30px;
                        font-size: .115rem;
                        line-height: .2rem;
                        margin-top: .06rem;
                        margin-left: .2rem;
                    }
                }
            }
            &:nth-of-type(1) {
                .fr .f_text:nth-of-type(2) .fr_tit {
                    height: 80px;
                    min-height: auto;
                    background: url(../img/2.jpg) no-repeat left top;
                    background-size: auto 100%;
                    padding-left: 88px;
                }
            }
            &:nth-of-type(2) {
                .fr .f_text:nth-of-type(1) .fr_tit {
                    height: 80px;
                    min-height: auto;
                    background: url(../img/3.jpg) no-repeat left top;
                    background-size: auto 100%;
                    padding-left: 88px;
                }
                .fr .f_text:nth-of-type(2) .fr_tit {
                    height: 80px;
                    min-height: auto;
                    background: url(../img/4.jpg) no-repeat left top;
                    background-size: auto 100%;
                    padding-left: 88px;
                }
            }
        }
    }

    .i_box5 {
        .box {
            padding: 10px 2%;
            .fl {
                width: 100%;
                padding: 0;
                h3 {
                    font-size: .16rem;
                    line-height: .32rem;
                }
                .text {
                    p {
                        font-size: .11rem;
                        line-height: .18rem;
                    }
                }
                a {
                }
            }
            .fr {
                width: 100%;
                img {
                    width: 100%;
                    margin-top: .1rem;
                }
            }
        }
    }

    .i_box4 {
        padding: .1rem 2%; 
        .list {
            margin-top: .05rem;
            .t_tit {
                height: .3rem!important;
                margin-bottom: .1rem!important;
                span {
                    line-height: .2rem;
                    font-size: .13rem;
                }
                a {
                    line-height: .2rem;
                    font-size: .11rem;
                    height: .2rem;
                }
            }
            .t_box {
                .fl {
                    width: 100%;
                    padding: 0;
                    img {
                        width: 100%;
                    }
                }
                .fr {
                    display: none;
                }
            }
            .gs_text {
                span {
                    font-size: .115rem;
                    height: .32rem;
                    line-height: .32rem;
                }
                p {
                    margin: .05rem 0;
                    font-size: .1rem;
                    line-height: .18rem;
                    height: auto;
                }
                i {
                    margin-top: .1rem;
                }
            }
        }
        .que_list ul li {
            margin-bottom: .15rem;
        }
    }

    .i_box5 .i_pic img {
        height: 60px;
        overflow: hidden;
    }

    .content {
        padding: 0 2%;
        p {
            font-size: .11rem;
            line-height: .19rem;
        }
    }

    .main_l {
        padding-bottom: .1rem;
        .text {
            h4 {
                font-size: .15rem;
                line-height: .36rem;
            }
            ul {
                padding: 5px 0;
                li {
                    height: 36px;
                    line-height: 36px;
                    font-size: .11rem;
                    a {}
                }
            }
        }
        .main_contact {
            display: none;
        }
    }

    .content_tit {
        padding: 10px 0;
        h3 {
            font-size: .14rem;
        }
        p {
            line-height: 36px;
            background: none;
            margin-top: 6px;
            span {
                font-size: 12px;
                margin: 0 10px;
            }
        }
    }
}

@media screen and (max-width: 350px) {


}

/****************************** media ******************************/